<template>
  <div
    ref="datepicker-wrapper"
    class="relative"
    :class="{
      'w-1/2 md:w-1/3': size === 'compact'
    }"
    @mouseover="flags.hover = 1"
    @mouseleave="flags.hover = 0"
  >
    <div class="w-full h-full absolute top-0 left-0 z-[2]">
      <div
        class="absolute z-[2] top-[0.875rem] right-[0.6875rem] w-5 h-5 flex place-items-center justify-center"
        :class="{
          'bg-gray-5': (flags.focused || status === 'default') && !disableDatepicker
        }"
      >
        <NuxtImg
          v-if="['default', 'hovered'].includes(status) && !currValue && !disableDatepicker"
          src="/icons/calendar.svg"
          alt="calendar"
          width="18"
          height="20"
          class="cursor-pointer"
          @click="showPicker = true"
        />

        <div
          v-else-if="status === 'hovered'"
          class="cursor-pointer"
          @click="currValue = ''"
        >
          <IconsClose height="24" />
        </div>

        <IconsCheckedBox
          v-else-if="status === 'success'"
          type="circle"
          fill="#193560"
        />
      </div>

      <AtomsInput
        ref="input"
        v-model="currValue"
        type="text"
        v-bind="$attrs"
        :theme="theme"
        :label="label"
        :model-type="modelType"
        :date-format="format"
        :error-message="errorMessage"
        :is-success="isSuccess"
        :class="{
          'pointer-events-none': flags.focused
        }"
        hide-icon
        maxlength="10"
        @show-datepicker="showPicker = true"
        @blur="flags.focus = 0"
        @focus="flags.focus = 1"
      />
    </div>

    <client-only>
      <date-picker
        ref="datepicker"
        v-model:value="currValue"
        v-model:open="showPicker"
        v-bind="$attrs"
        class="datepicker"
        :class="`is-${status}`"
        :type="type"
        :label="label"
        :value-type="valueType"
        :format="format"
        :disabled="disabled"
        :disabled-date="disableDate"
        @pick="pickDate"
      >
        <template #icon-calendar>
          <IconsCheckedBox
            v-if="status === 'success'"
            type="circle"
            fill="#193560"
          />

          <NuxtImg
            v-else
            src="/icons/calendar.svg"
            alt="calendar"
            width="18"
            height="20"
          />
        </template>
      </date-picker>
    </client-only>

    <span
      v-if="['focused', 'error'].includes(status) && fieldLabel"
      ref="label"
      class="absolute left-4 -top-2 px-1 text-xs leading-[1.125rem] text-white"
      :class="component.label"
    >
      {{ fieldLabel }}
    </span>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import 'vue-datepicker-next/index.css'

defineOptions({
  name: 'AtomsDatepicker',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },

  type: {
    type: String,
    default: 'date',
    validator: value => ['date', 'datetime', 'year', 'month', 'time', 'week'].includes(value)
  },

  format: {
    type: String,
    default: 'DD/MM/YYYY'
  },

  valueType: {
    type: String,
    default: 'DD/MM/YYYY'
  },

  modelType: {
    value: String,
    default: 'text',
    validator: value => ['text', 'date'].includes(value)
  },

  label: {
    type: String,
    default: ''
  },

  errorMessage: {
    type: String,
    default: ''
  },

  isSuccess: {
    type: Boolean,
    default: false
  },

  disableDate: {
    type: Function,
    default: () => false
  },

  disableDatepicker: {
    type: Boolean,
    default: false
  },

  size: {
    type: String,
    default: 'default',
    validator: value => ['default', 'compact'].includes(value)
  },

  theme: {
    type: String,
    default: 'datepicker',
    validator: value => ['light', 'datepicker'].includes(value)
  },

  /**
   * NOTE:
   * This is not necessary, this handling should be
   * outside the component, since its not a general
   * functionality
   */
  isMedicareExp: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'update',
  'update:modelValue'
])

const $attrs = useAttrs()

const currValue = proxyModel(props, 'modelValue')

const showPicker = ref(false)

const flags = reactive({
  focus: 0,
  hover: 0
})

const datepicker = ref(null)

watch(() => currValue.value, val => {
  emit('update', val)
})

const component = computed(() => {
  const label = getKey(status.value, {
    default: '',
    focused: 'bg-primary',
    error: 'bg-alert-error-stroke'
  })

  return {
    label
  }
})

const disabled = computed(() => {
  return $attrs?.disabled === '' ||
  $attrs?.disabled ||
  $attrs?.['aria-disabled'] === '' ||
  $attrs?.['aria-disabled']
})

const status = computed(() => {
  const invalidDate = currValue && `${datepicker.value?.currentValue}` === 'Invalid Date'

  if (
    props.isMedicareExp &&
    props.valueType === 'mm/yyyy' &&
    currValue.value !== null &&
    currValue.value.length === 7
  ) {
    const val = currValue.value.split('/')
    const currDate = dayjs().format('MM/YYYY').split('/')

    if (
      val.length === 2 &&
      ((val[0] >= currDate[0] && currDate[1] === val[1]) || val[1] > currDate[1]) &&
      props.isSuccess
    ) {
      return 'success'
    }
  }

  if (props.disabled) {
    return 'disabled'
  } else if (flags.hover) {
    return 'hovered'
  } else if (flags.focus) {
    return 'focused'
  } else if (
    props.errorMessage ||
    invalidDate
  ) {
    return 'error'
  } else if (
    props.isSuccess ||
    currValue.value
  ) {
    return 'success'
  }

  return 'default'
})

const fieldLabel = computed(() => {
  return (flags.focus && props.label) ||
      (props.success?.status && props.success?.message) ||
      (props.error?.status && props.error?.message)
})

onMounted(async () => {
  await nextTick()

  const datepickerEl = datepicker.value

  if (datepickerEl) {
    datepickerEl.getElementsByTagName('input')[0].setAttribute('tabindex', '-1')
  }
})

function pickDate (date) {
  currValue.value = dayjs(date).format(props.format)
}

// function formatter () {
//   return {
//     stringify: date => date ? dayjs(date).format(props.format) : null,
//     parse: date => date ? dayjs(date, props.format).toDate() : null
//   }
// }
</script>

<style lang="scss" src="./index.scss"></style>

